import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo/seo"
import Banner from "../../components/banner/banner"
import Links from "../../components/services/links/links"

export default function PersonalInjury({ data }) {
  return (
    <Layout>
      <SEO title="Personal Injury" />
      <Banner
        image={
          <Img
            fluid={data.placeholderImage.childImageSharp.fluid}
            style={{ height: "100%" }}
          />
        }
        title="Services"
      />
      <div className="width75">
        <h1 className="title">Personal Injury</h1>
        <Links type="about" />
        <div className="ribbon-top">
          <p>
            Our commitment to fighting your corner and personal approach is what
            makes us unique.{" "}
          </p>
          <p>
            Our Personal Injury team has one simple goal – we are committed to
            getting you the maximum compensation you deserve for your injury, as
            quickly as possible. We are friendly and approachable – and we
            remove any financial worry about making a claim, by taking your case
            on a ‘no win, no fee’ basis.
          </p>
          <p>
            Recovering from an injury can be an emotional and stressful time. If
            you have been involved in an accident, which was not your fault, we
            can help you obtain the compensation you are entitled to.
          </p>
          <p>
            Our Lawyers have wealth of experience dealing with accident and
            injury claims. We strongly believe that accident victims deserve to
            be compensated well and not only for the injuries they have suffered
            but any other losses that may have incurred whilst they are
            recovering from their injuries.
          </p>
          <p>
            We pride ourselves in being a law firm, which is accessible and
            offers a very professional and personal service. We have
            multilingual staff so that you can always feel comfortable and
            ensuring an easy communication.
          </p>
          <p>
            We offer various funding options including conditional fee
            arrangements, (no win no fee) and guarantee our clients keep 100% of
            their compensation. We are successful in approximately 98% of our
            cases.
          </p>
          <p>
            We also do clinical negligence work for victims of medical
            accidents. This involves complaints and claims against any
            healthcare practitioners including doctors, nurses, and dentists.
          </p>
          <p>
            We offer a free initial consultation and home/hospital visits when
            required.
          </p>
          <div className="center">
            <p>We handle every type of claim including:</p>
            <ul>
              <li>Accidents at work</li>
              <li>Road traffic accidents</li>
              <li>Industrial disease</li>
              <li>Slips and Trips</li>
              <li>Fatal accidents</li>
              <li>Criminal injuries compensation claims</li>
              <li>Support and help for rehabilitation</li>
              <li>Personal injuries</li>
              <li>Product liability claim</li>
              <li>Dental and domestic</li>
              <li>Holiday claim</li>
              <li>Brain and head injuries</li>
              <li>Fatal injuries</li>
              <li>Occupational health and disease</li>
            </ul>
          </div>
          <Img
            fluid={data.bottomImage.childImageSharp.fluid}
            style={{ height: "150px" }}
            imgStyle={{ objectPosition: "center 20%" }}
          />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    placeholderImage: file(relativePath: { eq: "tower-bridge.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bottomImage: file(relativePath: { eq: "wheelchair.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
